/*
 * @Author: xiaziwen
 * @Date: 2021-01-20 18:12:29
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 15:41:18
 * @FilePath: \crm-education\src\api\http.js
 */
import axios from 'axios'
import { message } from 'ant-design-vue'
import router from '@/router/index'
import helper from '@/utils/helper'

const baseURL =
  process.env.NODE_ENV === 'development'
    ? '/api'
    : process.env.VUE_APP_API_BASE_URL
    ? process.env.VUE_APP_API_BASE_URL
    : '/'

var instance = axios.create({
  timeout: 500000,
  baseURL,
  withCredentials: true
  // // transformRequest: [function (data) {
  // //     let ret = ''
  // //     ret = Qs.stringify(data)
  // //     return ret
  // // }],
  // validateStatus(status) {
  //   switch (status) {
  //     case 400:
  //       message.error('请求出错')
  //       break
  //     case 401:
  //       message.warning({
  //         message: '授权失败，请重新登录'
  //       })
  //       return
  //     case 403:
  //       message.warning({
  //         message: '拒绝访问'
  //       })
  //       break
  //     case 404:
  //       message.warning({
  //         message: '请求错误,未找到该资源'
  //       })
  //       break
  //     case 500:
  //       message.warning({
  //         message: '服务端错误'
  //       })
  //       break
  //   }
  //   return status >= 200 && status < 300
  // }
})

// 添加请求拦截器
instance.interceptors.request.use(
  function(config) {
    const accessToken = localStorage.getItem('accessToken') || ''
    const studId = localStorage.getItem('userId') || ''
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken
    }
    if (config.method === 'get') {
      if (config.params) {
        config.params.studId = studId
      } else {
        config.params = { studId }
      }
    } else if (config.method === 'post') {
      if (config.data) {
        config.data.studId = studId
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器即异常处理
instance.interceptors.response.use(
  response => {
    // const httpStatus = Number(response.status)
    const code = response.data.code || ''
    const msg = response.data.msg || ''
    if (code && code !== 200) {
      if (code === 1001) {
        message.error({
          content: msg
        })
        jumpToLogin()
      } else if (code === 1002) {
        // 国安网密码错误
        return Promise.resolve(response.data)
      } else {
        message.error(msg)
      }
      return Promise.reject(response)
    }
    return Promise.resolve(response.data)
  },
  err => {
    const data = err.response.data
    message.error({
      content: data.message || data.error || data.msg
    })
    return Promise.reject(err)
  }
)

function jumpToLogin() {
  const currentRouter = router.history.current
  if (currentRouter.meta.role.includes('STUDENT')) {
    helper.clearUserInfo()
    router.push({
      path: '/login/student',
      query: { redirect: currentRouter.fullPath }
    })
  } else {
    router.push({
      path: '/login/teacher',
      query: { redirect: currentRouter.fullPath }
    })
  }
}
export default instance
