import axios from './http'

export default {
    // 获取今日活动趋势
    getTodayActivityNum: (params = {}) => {
        return axios.get('/bigdata/todayDynamic/getTodayActivityNum', { params })
    },
    // 获取今日直播
    getTodayLiveInfoList: (params = {}) => {
        return axios.get('/bigdata/todayDynamic/getTodayLiveInfoList', { params })
    },
    // 获取今日实时动态
    getTodayTimeDynamicList: (params = {}) => {
        return axios.get('/bigdata/todayDynamic/getTodayTimeDynamicList', { params })
    },
    // 获取今日新增报读人数等
    getTodayCount: (params = {}) => {
        return axios.get('/bigdata/todayDynamic/getTodayCount', { params })
    },

    // 获取整体概况
    getWholeCount: (params = {}) => {
        return axios.get('/bigdata/wholeInfo/getWholeCount', { params })
    },
    // 获取专业学生数TOP5
    getMajorStudTopList: (params = {}) => {
        return axios.get('/bigdata/wholeInfo/getMajorStudTopList', { params })
    },
    // 获取热门专业TOP5
    getHotMajor: (params = {}) => {
        return axios.get('/bigdata/wholeInfo/getHotMajor', { params })
    },
    // 获取课程概况
    getWholeMajorCourse: (params = {}) => {
        return axios.get('/bigdata/wholeInfo/getWholeMajorCourse', { params })
    },

    // 获取活跃学生
    getStudyActive: (params = {}) => {
        return axios.get('/bigdata/studyMonitor/getStudyActive', { params })
    },
    // 获取课程讨论数
    getStudyCount: (params = {}) => {
        return axios.get('/bigdata/studyMonitor/getStudyCount', { params })
    },
    // 获取本周直播
    getWeekLiveList: (params = {}) => {
        return axios.get('/bigdata/studyMonitor/getWeekLiveList', { params })
    },

    // 获取毕业论文进行情况
    getGraduatThesisInfo: (params = {}) => {
        return axios.get('/bigdata/graduatMonitor/getGraduatThesisInfo', { params })
    },
    // 获取社会实践进行情况
    getSocialPracticeInfo: (params = {}) => {
        return axios.get('/bigdata/graduatMonitor/getSocialPracticeInfo', { params })
    },
    // 获取已毕业人数
    getGraduatCount: (params = {}) => {
        return axios.get('/bigdata/graduatMonitor/getGraduatCount', { params })
    },
    // 获取毕业资料完善情况
    getGraduatDataInfo: (params = {}) => {
        return axios.get('/bigdata/graduatMonitor/getGraduatDataInfo', { params })
    },
    // 获取毕业班学分获得情况
    getSatisfyGraduatInfo: (params = {}) => {
        return axios.get('/bigdata/graduatMonitor/getSatisfyGraduatInfo', { params })
    },
    // 获取实时动态数据
    getTimeDynamicList: (params = {}) => {
        return axios.get('/bigdata/graduatMonitor/getTimeDynamicList', { params })
    },

    // 获取本学期考试完成情况
    getExamFinishInfo: (params = {}) => {
        return axios.get('/bigdata/examMonitor/getExamFinishInfo', { params })
    },
    // 获取考试相关统计数据
    getExamCount: (params = {}) => {
        return axios.get('/bigdata/examMonitor/getExamCount', { params })
    },
    // 获取考点选择情况
    getCentreChoiceInfo: (params = {}) => {
        return axios.get('/bigdata/examMonitor/getCentreChoiceInfo', { params })
    },
    // 获取报考确认情况
    getConfirmInfo: (params = {}) => {
        return axios.get('/bigdata/examMonitor/getConfirmInfo', { params })
    },
    // 获取实时动态数据
    getExamTimeDynamicList: (params = {}) => {
        return axios.get('/bigdata/examMonitor/getTimeDynamicList', { params })
    },
}