import axios from './http'
export default {
  // 获取考试计划
  examPlan: (params = {}) => {
    return axios.get('/student/getExamPlan', { params })
  },
 // 获取考试预约列表
  appointmentList: (params = {}) => {
    return axios.get('/student/getExamAppointmentList', { params })
  },
  updataAppointment:(params = []) =>{
    return axios.post('/student/updataAppointment',{params})
  } ,

   // 获取大作业详情
  courseworkDetail: (params = {}) => {
    return axios.get('/student/getCoursework', { params })
  },
   // 上传大作业
  uploadCoursework: (data = {}) => {
    return axios.post('/student/uploadCoursework', data)
  },
  // 提交大作业
  submitCoursework: (data = {}) => {
    return axios.post('/student/saveCoursework', data)
  },
  //获取准考证信息
  examTicketInfo: (params = {})  => {
      return axios.get('/student/getExamTicketInfo', { params })
  },
  // 下载准考证
  getExamTicketInfoPdf: (params = {}) => {
      return axios.get('/student/getExamTicketInfoPdf', { params, responseType: 'blob' })
  },

  //获取考点
  getCentreByForId: (params = {}) =>{
    return axios.get('/student/getCentreByForId', { params })
  }
}
