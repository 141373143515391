/*
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:39:09
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 16:54:44
 * @FilePath: \crm-education\src\router\routes.js
 */
export default [
  {
    path: '/',
    name: 'Home',
    redirect: '/login/student',
    component: () => import('@/views/base/home')
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/common/login/index')
  // },
  {
    path: '/bigdata/today-dynamic',
    name: 'today-dynamic',
    meta: { title: '今日动态' },
    component: () => import('@/views/bigdata-screen/today-dynamic')
  },
  {
      path: '/bigdata/whole-info',
      name: 'whole-info',
      meta: { title: '整体概况' },
      component: () => import('@/views/bigdata-screen/whole-info')
  },
  {
      path: '/bigdata/study-monitor',
      name: 'study-monitor',
      meta: { title: '学情监测' },
      component: () => import('@/views/bigdata-screen/study-monitor')
  },
  {
       path: '/bigdata/graduat-monitor',
       name: 'graduat-monitor',
       meta: { title: '毕业监测' },
       component: () => import('@/views/bigdata-screen/graduat-monitor')
   },
   {
      path: '/bigdata/exam-monitor',
      name: 'exam-monitor',
      meta: { title: '考试监测' },
      component: () => import('@/views/bigdata-screen/exam-monitor')
  },
  {
    path: '/login/student',
    name: 'login_student',
    meta: { title: '学习平台登录' },
    component: () => import('@/views/common/login/student')
  },
  {
    path: '/login/teacher',
    name: 'login_teacher',
    meta: { title: '老师登录' },
    component: () => import('@/views/common/login/teacher')
  },
  {
    path: '/login/studLive/:channelNo',
    name: 'login_studLive',
    meta: { title: '直播查看登录' },
    component: () => import('@/views/common/login/studLive')
  },
  {
    path: '/teacherLive/login',
    name: 'login_teacherLive',
    meta: { title: '教师查看直播登录' },
    component: () => import('@/views/common/login/teacherLive')
  },
  {
    path: '/ouchStudLive/:channelNo',
    name: 'ouchStudLive',
    meta: { title: '直播列表' },
    component: () => import('@/views/studLive/home/index')
  },
  {
    path: '/forget',
    name: 'forget',
    meta: { title: '忘记密码' },
    component: () => import('@/views/common/login/forget')
  },
  {
    path: '/studlive/:roomId',
    name: 'studlive',
    meta: { title: '直播' },
    component: () => import('@/views/student/studlive/index')
  },
  {
    path: '/student',
    name: 'student_layout',
    component: () => import('@/layouts/Base'),
    children: [
      {
        path: '/student',
        name: 'student',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/home/index')
      },
      {
        path: '/student/my_study',
        name: 'student_my_study',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_study/index')
      },
      {
        path: '/student/my_exam',
        name: 'student_my_exam',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_exam/index')
      },
      {
        path: '/student/my_grade',
        name: 'student_my_grade',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_grade/index')
      },
      {
        path: '/student/my_school_role',
        name: 'student_my_school_role',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_school_role/index')
      },
      {
        path: '/student/my_textbook_send',
        name: 'student_my_textbook_send',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_textbook_send/index')
      },
      {
        path: '/student/my_class',
        name: 'student_my_class',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_class/index')
      },
      {
        path: '/student/my_graduat',
        name: 'student_my_graduat',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_graduat/index')
      },
      {
        path: '/student/my_admission_test',
        name: 'student_my_admission_test',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_admission_test/index')
      },
      {
        path: '/student/my_notice',
        name: 'student_my_notice',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/my_notice/index')
      },
      {
        path: '/student/user_center',
        name: 'student_user_center',
        meta: {
          role: ['STUDENT']
        },
        component: () => import('@/views/student/user_center/index')
      }
    ]
  },
  {
    path: '/teacherLive',
    name: 'teacherLive',
    meta: { title: '教师观看直播专题列表' },
    component: () => import('@/views/teacher/viewLive/liveIndex')
  },
  {
    path: '/teacherLive/list/:channelNo',
    name: 'teacherLiveList',
    meta: { title: '教师观看直播' },
    component: () => import('@/views/teacher/viewLive/live')
  },
  {
    path: '/teacher',
    name: 'teacher_layout',
    component: () => import('@/layouts/Base'),
    children: [
      {
        path: '/teacher',
        name: 'teacher',
        meta: {
          role: ['TEACHER']
        },
        component: () => import('@/views/teacher/home/index')
      },
      {
        path: '/teacher/course',
        name: 'teacher_course',
        meta: {
          role: ['TEACHER']
        },
        component: () => import('@/views/teacher/course/index')
      },
      {
        path: '/teacher/task',
        name: 'teacher_task',
        meta: {
          role: ['TEACHER']
        },
        component: () => import('@/views/teacher/task/index')
      },
      {
        path: '/teacher/test',
        name: 'teacher_test',
        meta: {
          role: ['TEACHER']
        },
        component: () => import('@/views/teacher/test/index')
      },
      {
        path: '/teacher/thesis',
        name: 'teacher_thesis',
        meta: {
          role: ['TEACHER']
        },
        component: () => import('@/views/teacher/thesis/index')
      },
      {
        path: '/teacher/notice',
        name: 'teacher_notice',
        meta: {
          role: ['TEACHER']
        },
        component: () => import('@/views/teacher/notice/index')
      }
    ]
  },
  {
    path: '/protal',
    name: 'protal',
    component: () => import('@/layouts/Protal'),
    children: [
      {
        path: '/protal',
        name: 'protal_home',
        component: () => import('@/views/protal/home')
      },
      {
        path: '/protal/list',
        name: 'protal_list',
        component: () => import('@/views/protal/list')
      },
      {
        path: '/protal/list/detail',
        name: 'protal_list_detail',
        component: () => import('@/views/protal/list/detail')
      }
    ]
  },
  {
    path: '*',
    name: 'error_404',
    component: () => import('@/views/common/error/404.vue')
  }
]
