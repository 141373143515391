/*
 * @Author: xiaziwen
 * @Date: 2021-01-20 18:11:25
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 17:13:18
 * @FilePath: \crm-education\src\api\index.js
 */

import axios from './http'
import graduat from './studentGraduat'
import appointment from './studentAppointment'
import bigdata from "./bigdata";
/**
 * 学生相关接口
 */

// 登录
export default {
  student: {
    ...graduat,
    ...appointment,
    verifyImage: (params = {}) => {
      return axios.get('/captcha/imageUrl', { params })
    },
    sendSms: (organizationCode, phoneNumber) => {
      return axios.get(`/captcha/sendSms/${organizationCode}/${phoneNumber}`)
    },
    sendSmsNotVerifyUser: (organizationCode, phoneNumber) => {
      return axios.get(`/captcha/sms/${organizationCode}/${phoneNumber}`)
    },
    checkSmsCode: (phoneNumber, code) => {
      return axios.get(`/captcha/checkSmsCode/${phoneNumber}/${code}`)
    },
    submitPassword: (data = {}) => {
      return axios.post('/captcha/updatePassword', data)
    },
    modifyPassword: (data = {}) => {
      return axios.post('/studInfo/updateStudPassword', data)
    },
    login: (data = {}) => {
      return axios.post('/student/login', data)
    },
    quickLogin: (params = {}) => {
      return axios.get('/student/quickLogin', { params })
    },
    wxLogin: (params = {}) => {
        return axios.get('/student/wxLogin', { params })
    },
    //绑定微信登录
    bdingLogin: (data = {}) => {
        return axios.post('/student/bdingLogin', data)
    },
    getGrade: (params = {}) => {
      return axios.get('/student/getGrade', { params })
    },
    getExam: (params = {}) => {
      return axios.get('/student/getMyExam', { params })
    },
    getLive: (data = {}) => {
      return axios.post('/student/getRecentlyLive', data)
    },
    getAllLive: (data = {}) => {
      return axios.post('/student/getAllLiveSpecial', data)
    },
    getAllLiveDetail: (params = {}) => {
      return axios.get('/student/getLiveBySpecial', { params })
    },
    getTerm: (params = {}) => {
      return axios.get('/student/getTerm', { params })
    },
    getCourse: (params = {}) => {
      return axios.get('/student/getSelectCourse', { params })
    },
    getCourseDiscuss: (params = {}) => {
      return axios.get('/student/getCourseDiscuss', { params })
    },
    setCourseDiscuss: (data = {}) => {
      return axios.post('/student/setCourseDiscuss', data)
    },
    getCourseData: (params = {}) => {
      return axios.get('/student/getCourseData', { params })
    },
    getShapeBookInfo: (params = {}) => {
      return axios.get('/student/getShapeBookInfo', { params })
    },
    getShapeGradeInfo: (params = {}) => {
      return axios.get('/student/getShapeGradeInfo', { params })
    },
    getLivePlan: (params = {}) => {
      return axios.get('/student/getLivePlan', { params })
    },
    getRecentlyLive: (data = {}) => {
      return axios.post('/student/getRecentlyLive', data)
    },
    getLiveViewRecord: (data = {}) => {
      return axios.post('/student/getLiveViewRecord', data)
    },
    // 获取某月直播安排
    getCurrentMonthLiveMsg: (data = {}) => {
      return axios.post('/student/getCurrentMonthLiveMsg', data)
    },
    getExamPlan: (params = {}) => {
      return axios.get('/student/getExamPlan', { params })
    },
    getCredit: (params = {}) => {
      return axios.get('/student/getPointDetail', { params })
    },
    getStudProve: (params = {}) => {
      return axios.get('/student/getStudProve', { params })
    },
    updateStudProve: (data = {}) => {
      return axios.post('/student/updateStudProve', data)
    },
    getTextBook: (params = {}) => {
      return axios.get('/student/getTextBook', { params })
    },
    getMyTeacher: (params = {}) => {
      return axios.get('/student/getMyTeacher', { params })
    },
    getMySchoolMate: (params = {}) => {
      return axios.get('/student/getMySchoolmate', { params })
    },
    getNotice: (params = {}) => {
      return axios.get('/student/getInform', { params })
    },
    setNoticeRead: (data = {}) => {
      return axios.post('/student/setInformIsRead', data)
    },
    getMyMessage: (params = {}) => {
      return axios.get('/student/getMyMessage', { params })
    },
    setMessageRead: (data = {}) => {
      return axios.post('/student/setMessageIsRead', data)
    },
    getNoReadCount: (params = {}) => {
      return axios.get('/student/getNoReadCount', { params })
    },
    getForceAlertNotice: (params = {'popType':2}) => {
      return axios.get('/student/getForceAlertNotice', { params })
    },
    getStudentInfo: (params = {}) => {
      return axios.get('/student/getStudInfo', { params })
    },
    changeStudentInfo: (data = {}) => {
      return axios.post('/student/updateStudInfo', data)
    },
    uploadStudentThumba: (data = {}) => {
      return axios.post('/student/uploadImg', data)
    },
    setLearnCount: (data = {}) => {
      return axios.post('/student/setLearningCount', data)
    },
    // 录取通知书
    getNoticeBook: (params = {}) => {
      return axios.get('/student/getAdmissionNotice', { params })
    },
    // 获取直播地址
    getLiveUrl: (data = {}) => {
      return axios.post('/student/getLiveUrl', data)
    },
    // 校验是否是小鱼易连直播
    checkXyLive: (data = {}) => {
        return axios.post('/student/checkXyLive', data)
    },
    // 判断是否是试点专业
    isSdRuleCode: (params = {}) => {
        return axios.get('/ouchn/isSdRuleCode', { params })
    },
    // 获取课程学习地址
    getCourseStudyUrl: (data = {}) => {
      return axios.post('/ouchn/getCourseStudyUrl', data)
    },
    // 获取拓展课程学习地址
    getExpandCrsUrl: (data = {}) => {
      return axios.post('/student/getExpandCrsUrl', data)
    },
    // 查看物流详情
    getDeliveInfo: (params = {}) => {
      return axios.get('/student/getLogisticsDetail', { params })
    },
    updatePasswordAndPhone: (data = {}) => {
      return axios.post('/studInfo/updateStudPasswordAndPhone', data)
    },
    getStudentOuchPassword: (params = {}) => {
      return axios.get('/studInfo/getStudOuchPassword', { params })
    },
    updateOuchPassword: (data = {}) => {
      return axios.post('/studInfo/updateStudOuchPassword', data)
    },
    // 根据频道号，获取直播列表
    getLiveChannelNo: (params = {}) => {
      return axios.get('/student/getLiveChannelNo', { params })
    },
    checkEnterschoolUser: (params = {}) => {
        return axios.get('/studentExam/checkEnterschoolUser', { params })
    },
    getEnterSchoolUser: (params = {}) => {
      return axios.get('/studentExam/getEnterschoolUser', { params })
    },
    // 下载录取通知书
    getAdmissionNoticePdf: (params = {}) => {
      return axios.get('/student/getAdmissionNoticePdf', { params, responseType: 'blob' })
    },
    updateLiveCount:(params)=>{
      return axios.get(`/student/updateLiveCount`,{ params: { liveId: params.liveId,studId:params.studId }})
    },
    getOStudId: (params = {}) => {
      return axios.get('/student/getOStudId', { params })
    },
    getRelationAccountData: (params = {}) => {
        return axios.get('/student/getRelationAccountData', { params })
    },
    unboundData: (data = {}) => {
        return axios.post('/student/unboundData', data)
    },
    bindingAccount: (data = {}) => {
        return axios.post('/student/bindingAccount', data)
    },
    //上传形考成绩文件
    uploadShapeGradeFile :(data = {}) => {
      return axios.post('/student/uploadShapeGradeFile', data)
    },
    //上传形考成绩文件
    deleteShapeGradeFile :(data = {}) => {
      return axios.post('/student/deleteShapeGradeFile', data)
    },
    checkSubscribeWx: (params = {}) => {
        return axios.get('/student/checkSubscribeWx', { params })
    },
  },
  protal: {
    getOrganizationByDomain: (params = {}) => {
      return axios.get(`/portal/setting/getSettingByOrganizationIdOrDomain`, {
        params: { param: params.domain }
      })
    },
    getBanner: (params = {}) => {
      return axios.get(`/portal/banner/getPortalBannerByOrganizationId/${params.organizationId}/${params.bannerType}`)
    },
    getLink: (params = {}) => {
      return axios.get(`/portal/link/getPortalLinkByOrganizationId/${params.organizationId}`)
    },
    getColumns: (params = {}) => {
      return axios.get(`/portal/columns/getPortalColumnsByOrganizationId/${params.organizationId}`)
    },
    getInfoByColumnId: (params = {}) => {
      return axios.get(`/portal/information/getInformationListByColumnId/${params.columnsId}
      `)
    }
  },
  teacher:{
    login: (data = {}) => {
      return axios.post('/teacher/login', data)
    },
    getLiveSpecialByOrganization:(params)=>{
      return axios.get(`/student/getLiveSpecialByOrganization`,{ params})
    },
    getTodayLiveSpecialByOrganization:(params = {})=>{
        return axios.get(`/student/getTodayLiveSpecialByOrganization`,{ params})
    },
    getTermByOrganizationId: (params = {}) => {
        return axios.get('/student/getTermByOrganizationId', { params })
    },
    teacherQuickLogin: (params = {}) => {
      return axios.get('/teacher/teacherQuickLogin', { params })
    },
    getTodayLiveInfoByOrganization:(params = {})=>{
        return axios.get(`/student/getTodayLiveInfoByOrganization`,{ params})
    },
  },
    bigdata: {
        ...bigdata
    }
}
