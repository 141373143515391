import axios from './http'
export default {
  // 获取毕业计划
  graduationPlan: (params = {}) => {
    return axios.get('/student/getGraduationPlan', { params })
  },
  // 获取毕业作业列表
  workList: (params = {}) => {
    return axios.get('/student/getGraduationThesisList', { params })
  },
  // 获取毕业作业详情
  workDetail: (params = {}) => {
    return axios.get('/student/getGraduationThesisDetail', { params })
  },
  // 上传毕业作业
  uploadWork: (data = {}) => {
    return axios.post('/student/uploadWork', data)
  },
  // 提交毕业作业
  submitWork: (data = {}) => {
    return axios.post('/student/saveThesisRecords', data)
  },
  //获取毕业资料信息
    getGraduatData: (params = {}) => {
      return axios.get('/student/getGraduationData', { params })
  },
  //确认下载毕业生登记表
  confirmGradRegister: (data = {}) => {
      return axios.post('/student/confirmGradRegister', data)
  },
  //确认学籍信息
  updateProveConfirm: (data = {}) => {
      return axios.post('/student/updateProveConfirm', data)
  },
  // 上传电子毕业照
  uploadStudentGradImg: (data = {}) => {
      return axios.post('/student/uploadGradImg', data)
  },
  // 保存电子毕业照
  updateGradImg: (data = {}) => {
      return axios.post('/student/updateGradImg', data)
  },
  //删除电子毕业照
  removeStudentGradImg:(data = {}) => {
    return axios.post('/student/removeStudentGradImg', data)
  },
  //是否允许上传毕业照
  isUploadGradImg:(params = {}) => {
    return axios.get('/student/isUploadGradImg', {params})
  },
  // 上传电子毕业照采集码
  uploadStudentGradImgCode: (data = {}) => {
      return axios.post('/student/uploadGradImgCode', data)
  },

}
