/*
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:09:28
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-29 16:07:51
 * @FilePath: \crm-education\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import { message } from 'ant-design-vue'
import helper from '@/utils/helper'
let head = document.getElementsByTagName('head');
let meta = document.createElement('meta');
meta.name = 'referrer';
//根据实际情况修改referrer的值，可选值参考上文
meta.content = 'no-referrer';
head[0].appendChild(meta);
Vue.use(VueRouter)

// 避免到当前位置的冗余导航 (重复触发了同一个路由)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 是否具备权限 12
  const routeRoles = to.meta.role
  const { userRole, userId, studentName, accessToken, studentImg, isCurrentTerm } = helper.getUserInfo()
  if (userRole) {
    store.commit('setRole', userRole)
  }
  if (userId) {
    // 同步local学生用户信息到store
    store.commit('setUser', { studId: userId, studentName, accessToken, studentImg, isCurrentTerm })
  }
  if (routeRoles && Object.prototype.toString.call(routeRoles) === '[object Array]') {
    if (routeRoles.includes(userRole)) {
      next()
    } else {
      message.error('暂无权限, 请登录!')
      if (routeRoles.includes('TEACHER')) {
        next({ name: 'login_teacher' })
      } else if (routeRoles.includes('STUDENT')) {
        next({ name: 'login_student' })
      } else {
        next({ name: 'login_studLive' })
      }
    }
  } else {
    next()
  }
})

export default router
